import React from 'react'

type Props = {}

// TODO: Implement functionality for configuring leave types and their respective allowed days for administrators.

function ConfigureLeaves({ }: Props) {
    return (
        <div>TODO: ConfigureLeaves</div>
    )
}

export default ConfigureLeaves