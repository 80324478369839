import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import Avatar from "@mui/material/Avatar";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { HiLogout } from "react-icons/hi";
import Logo from "../components/ui/Logo";
import Scrollbar from "./scrollbar";
import navConfig from "../utils/navConfig";
import { NAV } from "../utils/configLayout";
import { useStateContext } from "../context";
import { capitalizeName } from "../utils/Util";

function Nav() {
  const [openNav, setOpenNav] = useState(false);
  const [, setCookie] = useCookies(["logged_in", "token", "accessToken"]);
  const stateContext = useStateContext();
  const user = stateContext.state.authUser;
  const authorizedRoutes = navConfig.filter((route) =>
    route.permissions?.includes(user?.role || "user")
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (isLgUp && openNav) {
      setOpenNav(false);
    }
  }, [isLgUp, openNav]);

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  const handleCloseNav = () => {
    setOpenNav(false);
  };

  const handleNavItemClick = (path: string) => {
    navigate(path);
    if (!isLgUp) {
      handleCloseNav();
    }
  };

  const renderAccount = (
    <Link to="/view-profile" style={{ textDecoration: "none", color: "white" }}>
      <Box
        sx={{
          my: 3,
          mx: 2.5,
          py: 2,
          px: 2.5,
          display: "flex",
          borderRadius: 1.5,
          alignItems: "center",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
          },
        }}
      >
        <Avatar src={""} alt="photoURL" />
        <Box sx={{ ml: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            {capitalizeName(user?.firstName)} {capitalizeName(user?.lastName)}
          </Typography>
          <Typography variant="body2" sx={{ color: "#BDBDBD", fontSize: 12 }}>
            {user?.jobTitle}
          </Typography>
        </Box>
      </Box>
    </Link>
  );

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {authorizedRoutes.map((item) => (
        <NavItem
          key={item.title}
          item={item}
          onClick={() => handleNavItemClick(item.path)}
        />
      ))}
      <ListItemButton
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: "body2",
          color: "#fff",
          textTransform: "capitalize",
          fontWeight: "fontWeightMedium",
        }}
        onClick={() => {
          setCookie("logged_in", null, { path: "/" });
          setCookie("token", null, { path: "/" });
          setCookie("accessToken", null, { path: "/" });
          navigate("/signin");
          window.location.reload();
        }}
      >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          <HiLogout size={22} />
        </Box>
        <Box component="span">Logout</Box>
      </ListItemButton>
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        my: -2.5,
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Logo width="180px" />
      {renderAccount}
      {renderMenu}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {isLgUp ? (
        <Box
          sx={{
            backgroundImage: "linear-gradient(to right, #006699, #004477)",
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <>
          <IconButton
            onClick={openNav ? handleCloseNav : handleOpenNav}
            sx={{
              my: -1,
              position: "fixed",
              top: 16,
              left: 16,
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          <AppBar
            position="fixed"
            sx={{
              display: "block",
              height: 56, // Smaller height for AppBar
              backgroundImage: "linear-gradient(to right, #006699, #004477)",
            }}
          >
            <Toolbar
              sx={{
                minHeight: 56,
                display: "flex",
                justifyContent: "flex-start",
                my: -2,
              }}
            >
              <Logo width="150px" />
            </Toolbar>
          </AppBar>
          <Drawer
            open={openNav}
            onClose={handleCloseNav}
            PaperProps={{
              sx: {
                width: NAV.WIDTH,
                backgroundImage: "linear-gradient(to right, #006699, #004477)",
              },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </Box>
  );
}

export default Nav;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  onOpenNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item, onClick }: any) {
  const RouterLink = forwardRef(({ href, ...other }: any, ref) => (
    <Link ref={ref} to={href} {...other} />
  ));
  const { pathname } = useLocation();
  const active = pathname === item.path;

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      onClick={onClick}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "#fff",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.5),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>
      <Box component="span">{item.title}</Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};
